import React from 'react'

import TopHeader from './TopHeader'
import MainHeader from './MainHeader'

const Header = (): React.ReactElement => {
  return (
    <header>
      <TopHeader />
      <MainHeader />
    </header>
  )
}

export default Header
