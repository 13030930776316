import React from 'react'
import Input from './Input'

const MainHeader = (): React.ReactElement => {
  return (
    <div id="header">
      <div className="container">
        <div className="row main-header-row">
          <div className="col-md-3">
            <div className="header-logo">
              <a href="#" className="logo">
                {/* <img src="./img/logo.png" alt="" /> */}
                Shop Title
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <Input />
          </div>

          <div className="col-md-3 clearfix">
            <div className="header-ctn">
              <div className="dropdown">
                <a
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  aria-expanded="true"
                >
                  <i className="fa fa-shopping-cart"></i>
                  <span>Your Cart</span>
                  <div className="qty">
                    <span className="snipcart-items-count"></span>
                  </div>
                </a>
                <div className="cart-dropdown">
                  <div className="cart-summary">
                    <small>
                      <span className="snipcart-items-count"></span> Item(s)
                      selected
                    </small>
                    <h5>
                      Total price:{' '}
                      <span className="snipcart-total-price"></span>
                    </h5>
                  </div>
                  <div className="cart-btns">
                    <a href="#" className="snipcart-checkout">
                      View Cart
                    </a>
                    <a href="#" className="snipcart-checkout">
                      Checkout <i className="fa fa-arrow-circle-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="menu-toggle">
                <a href="#">
                  <i className="fa fa-bars"></i>
                  <span>Menu</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainHeader
