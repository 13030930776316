import React, { useState } from 'react'
import { PageProps } from 'gatsby'
import { InstantSearch } from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'
import Navigation from '../Navigation'
import Footer from './Footer/Footer'
import Header from './Header/Header'

type Props = {}

const Layout: React.FC<PageProps<Props>> = ({ children, location }) => {
  const searchClient = algoliasearch(
    'J0KSSS492W',
    'd772c7c030c9d4c4c8891f8ed56c70e0'
  )
  const [query, setQuery] = useState('')

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={'Products'}
      onSearchStateChange={({ query }) => setQuery(query)}
    >
      <Header />
      <Navigation pathname={location.pathname} />
      {children}
      <Footer />
    </InstantSearch>
  )
}

export default Layout
