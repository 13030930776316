import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { TopHeaderQuery } from '../../../../types/graphql-types'

const TopHeader = (): React.ReactElement => {
  const data = useStaticQuery<TopHeaderQuery>(query)

  const { tel, email, address } = data.site.siteMetadata

  return (
    <div id="top-header">
      <div className="container">
        <ul className="header-links pull-left">
          <li>
            <a href="#">
              <i className="fa fa-phone"></i>
              {tel}
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-envelope-o"></i> {email}
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-map-marker"></i> {address}
            </a>
          </li>
        </ul>
        <ul className="header-links pull-right">
          <li>
            <a href="#">
              <i className="fa fa-dollar"></i> USD
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-user-o"></i> My Account
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

const query = graphql`
  query TopHeader {
    site {
      siteMetadata {
        tel
        email
        address
      }
    }
  }
`

export default TopHeader
