import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import NavItem from './NavItem'
import { MenuQuery } from '../../types/graphql-types'

type Props = {
  pathname: string
}

const Navigation = ({ pathname }: Props): React.ReactElement => {
  const {
    allContentstackCategory: { nodes: menuItems },
  } = useStaticQuery<MenuQuery>(query)

  return (
    <nav id="navigation">
      <div className="container">
        <div id="responsive-nav">
          <ul className="main-nav nav navbar-nav">
            <NavItem title="Home" url="/" isActive={pathname === '/'} />
            {menuItems.map(({ id, title, url }) => (
              <NavItem
                title={title}
                url={url}
                isActive={pathname === url}
                key={id}
              />
            ))}
          </ul>
        </div>
      </div>
    </nav>
  )
}

const query = graphql`
  query Menu {
    allContentstackCategory {
      nodes {
        id
        title
        url
      }
    }
  }
`

export default Navigation
