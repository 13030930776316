import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Link from 'gatsby-link'
import { FooterQuery } from '../../../../types/graphql-types'

const Footer = (): React.ReactElement => {
  const data = useStaticQuery<FooterQuery>(query)

  const content = data.allContentstackFooter.nodes[0]

  const about = content.about_group
  const categories = content.categories_group.reference
  const information = content.information.reference
  const service = content.service.link

  return (
    <footer id="footer">
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-xs-6">
              <div className="footer">
                <h3 className="footer-title">About Us</h3>
                <p>{about.about_us}</p>
                <ul className="footer-links">
                  <li>
                    <a href="#">
                      <i className="fa fa-map-marker"></i>
                      {about.address}
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-phone"></i>
                      {about.phone}
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-envelope-o"></i>
                      {about.email}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-3 col-xs-6">
              <div className="footer">
                <h3 className="footer-title">Categories</h3>
                <ul className="footer-links">
                  {categories.map((category) => (
                    <li key={category.id}>
                      <Link to={category.url}>{category.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="clearfix visible-xs"></div>

            <div className="col-md-3 col-xs-6">
              <div className="footer">
                <h3 className="footer-title">Information</h3>
                <ul className="footer-links">
                  {information.map((info) => (
                    <li key={info.id}>
                      <Link to={info.url}>{info.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-md-3 col-xs-6">
              <div className="footer">
                <h3 className="footer-title">Service</h3>
                <ul className="footer-links">
                  {service.map((item, index) => (
                    <li key={index}>
                      <Link to={item.href}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="bottom-footer" className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <ul className="footer-payments">
                <li>
                  <a href="#">
                    <i className="fa fa-cc-visa"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-credit-card"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-cc-paypal"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-cc-mastercard"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-cc-discover"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-cc-amex"></i>
                  </a>
                </li>
              </ul>
              <span className="copyright">
                Copyright &copy;
                <script>document.write(new Date().getFullYear());</script> All
                rights reserved | This template is made with{' '}
                <i className="fa fa-heart-o" aria-hidden="true"></i> by{' '}
                <a href="https://colorlib.com" target="_blank" rel="noreferrer">
                  Colorlib
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

const query = graphql`
  query Footer {
    allContentstackFooter {
      nodes {
        about_group {
          about_us
          address
          phone
          email
        }
        categories_group {
          reference {
            id
            title
            url
          }
        }
        information {
          reference {
            id
            title
            url
          }
        }
        service {
          link {
            title
            href
          }
        }
      }
    }
  }
`

export default Footer
