import React from 'react'
import { Link } from 'gatsby'

type Props = {
  title: string
  url: string
  isActive: boolean
}

const NavItem = ({ title, url, isActive }: Props): React.ReactElement => {
  return (
    <li className={isActive ? 'active' : undefined}>
      <Link to={url}>{title}</Link>
    </li>
  )
}

export default NavItem
