import React from 'react'
import { Link } from 'gatsby'
import { connectSearchBox } from 'react-instantsearch-dom'
import './style.css'

const Input = connectSearchBox(({ refine, ...rest }) => (
  <form className="header-search">
    <input
      className="input"
      placeholder="Search here"
      onChange={(e) => refine(e.target.value)}
      {...rest}
    />
    <Link to="/category/cars" className="search-btn">
      Search
    </Link>
  </form>
))

export default Input
